export interface KollegenauslieferungFacetSearchData extends KollegenauslieferungSearchData {
    searchWord: string;
    chipDataString: string;
    // need all fields from SearchData for proper filtering and correct count numbers
}

export interface KollegenauslieferungSearchData {
    receiverNumbers: string[];
    hauptwarengruppenVME: string[];
    deliver_abroad?: string;
    deliver_island: string,
    delivery_colleague?: string;
    searchFacetFields: Array<any>;
    zipCircleShort?: string; 
    zipCircleShortExist: boolean;
    chipDataString?: string;
}

function defaultData(): KollegenauslieferungSearchData {
    return {
        receiverNumbers: [],
        hauptwarengruppenVME: [],
        deliver_abroad: 'all',
        deliver_island: 'all',
        delivery_colleague: 'all',
        searchFacetFields: [],
        zipCircleShort: '',
        zipCircleShortExist: false,
    };
}

export default {
    defaultData,
};
