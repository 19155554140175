import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import gspUserRoleDetail, { GspUserRoleDetail, Value } from '../model/gspUserRoleDetail';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.GspUserRoleDetail');
export interface BackendGspUserRoleDetail {
  getGspUserRoleDetail(id: string): AxiosPromise<GspUserRoleDetail>;
  getGspUserRoleDetails: (searchParams: SearchParams, userRoleId?: string) => AxiosPromise<Value>;
  deleteGspUserRoleDetail(id: string): AxiosPromise;
  updateGspUserRoleDetail(GspUserRoleDetail: GspUserRoleDetail): AxiosPromise<any>;
  updateGspUserRoleDetailMultipleRoles(
    GspUserRoleDetail: GspUserRoleDetail,
    moduleRoleChanges: any[]
  ): AxiosPromise<any>;

  addUserFromManageMaxService(data: any): AxiosPromise<any>;
  deleteUserFromManageMaxService(data: any): AxiosPromise<any>;
  checkContactInManageMaxByZrNummer(data: any): AxiosPromise<any>;
  getWarengruppeForMax(email: string): AxiosPromise<any>;

}

export const defaultBackendGspUserRoleDetail: BackendGspUserRoleDetail = {
  getGspUserRoleDetail(id: string): AxiosPromise<GspUserRoleDetail> {
    let url = `${URLS.gspUserRoleDetailOdata}/${id}`;
    return instance.get<GspUserRoleDetail>(url);
  },
  getGspUserRoleDetails(searchParams: SearchParams, userRoleId?: string): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq('userRoleId', userRoleId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.gspUserRoleDetailOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getGspUserRoleDetails${url}`);
    return instance.get<Value>(url);
  },

  deleteGspUserRoleDetail(id: string): AxiosPromise {
    logger.debug('deleteGspUserRoleDetail');
    return instance.delete(`${URLS.gspUserRoleDetail}/${id}`);
  },
  updateGspUserRoleDetail(GspUserRoleDetail: GspUserRoleDetail): AxiosPromise<any> {
    logger.debug('updateGspUserRoleDetail');
    return instance.put<GspUserRoleDetail>(
      `${URLS.gspUserRoleDetail}/update`,
      gspUserRoleDetail.toAPI(GspUserRoleDetail)
    );
  },
  updateGspUserRoleDetailMultipleRoles(
    GspUserRoleDetail: GspUserRoleDetail,
    moduleRoleChanges: any[]
  ): AxiosPromise<any> {
    logger.debug('updateGspUserRoleDetailMultipleRoles');
    return instance.put<GspUserRoleDetail>(`${URLS.gspUserRoleDetail}/UpdateMultipleRoles`, {
      ModuleRoleChanges: moduleRoleChanges,
      ...gspUserRoleDetail.toAPI(GspUserRoleDetail),
    });
  },

  //
  checkContactInManageMaxByZrNummer(
    data: any): AxiosPromise<any> {
    logger.debug('checkContactInManageMaxByZrNummer');
    return instance.put<any>(`${URLS.gspUserRoleDetail}/CheckContactInManageMaxByZrNummer`, data);
  },
  addUserFromManageMaxService(
    data: any): AxiosPromise<any> {
    logger.debug('addUserFromManageMaxService');
    return instance.post<any>(`${URLS.gspUserRoleDetail}/AddManageMax`, data);
  },
  deleteUserFromManageMaxService(payload: any): AxiosPromise {
    logger.debug('deleteUserFromManageMaxService');
    return instance.delete(`${URLS.gspUserRoleDetail}/DeleteFromMaxService`,  { data: payload });
    // return instance.delete(`${URLS.gspUserRoleDetail}/DeleteFromMaxService/${email}`);
  },
  getWarengruppeForMax(email: string): AxiosPromise<any> {
    logger.debug('GetWarengruppeForMax');
    return instance.put<any>(`${URLS.gspUserRoleDetail}/GetWarengruppeForMax`,`"${email}"`);
  },

};
