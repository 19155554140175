import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import kollegenauslieferungSearchData, {
  KollegenauslieferungSearchData,
} from '@/shared/model/smallPayloadModels/kollegenauslieferungSearchData';
import { ROUTES } from '@/router/routesEnum';
import DateField from '@/components/_common/date-field/DateField.vue';
import { CONST } from '@/shared/utils/Constants';

import GeneralUtils from '@/shared/utils/generalUtils';

const logger = new Logger('login');
const invoiceModule = namespace('invoice');
const authModule = namespace('auth');
const userDataModule = namespace('userData');
const tblKollegenauslieferungModule = namespace('tblKollegenauslieferung');

@Component({
  components: { D4yTable, DateField },
})
export default class KollegenauslieferungComponent extends Vue {
  @tblKollegenauslieferungModule.Action('getKollegenauslieferung')
  private actiongetKollegenauslieferungData!: any;
  @tblKollegenauslieferungModule.Getter('getKollegenauslieferungs')
  private getKollegenauslieferung!: any;
  @tblKollegenauslieferungModule.Action('updateKollegenauslieferungSearchData')
  private actionUpdateKollegenauslieferungSearchData!: any;
  @tblKollegenauslieferungModule.Getter('getKollegenauslieferungSearchData')
  private getKollegenauslieferungSearchData!: any;
  @tblKollegenauslieferungModule.Getter('getKollegenauslieferungsSearchParams')
  private kollegenauslieferungsSearchParams!: any;
  @tblKollegenauslieferungModule.Action('getKollegenauslieferungFacetSearch')
  private actionGetKollegenauslieferungFacetSearch!: any;
  @tblKollegenauslieferungModule.Getter('isKollegenauslieferungSearchDataEmpty')
  private isKollegenauslieferungSearchDataEmpty!: any;
  @tblKollegenauslieferungModule.Getter('getTKollegenauslieferungsIsLoading')
  private kollegenauslieferungsIsLoading!: boolean;

  private facetSearch = false;
  private menuDisabled = true;
  private searchWord = '';
  private menuItems: any = [];
  private chipData: Array<any> = [];
  private loadingFacet = false;
  private searchPayload: any = {};

  @Watch('getKollegenauslieferungSearchData', { deep: true })
  public async onOptionsFilterChanged(newVal: KollegenauslieferungSearchData, oldVal: KollegenauslieferungSearchData) {
    const areObjectsEqual = GeneralUtils.areObjectsEqual(newVal, oldVal); // decided to ignore 2 props than include logic for them in `search-form` component
   
    if (areObjectsEqual) {
      return;
    }
    let payload: any = {
      kollegenauslieferungSearchData: {
        receiverNumbers: newVal.receiverNumbers,
        hauptwarengruppenVME: newVal.hauptwarengruppenVME,
        deliver_abroad: newVal.deliver_abroad,
        deliver_island: newVal.deliver_island,
        delivery_colleague: newVal.delivery_colleague,
        searchFacetFields: this.kollegenauslieferungsSearchParams.andClauseFieldsIds,
      },
    };
    this.searchPayload = payload.kollegenauslieferungSearchData;

    this.kollegenauslieferungsSearchParams.dataOption.page = 1;
    await this.actiongetKollegenauslieferungData(payload)
      .then((result: any) => {})
      .catch((err: any) => {
        logger.error(err);
      });
  }

  async created() {
    if (!this.getKollegenauslieferungSearchData) {
      this.getKollegenauslieferungSearchData = kollegenauslieferungSearchData.defaultData(); // `{}` returned  error
    }

    const payload = {
      searchParams: this.kollegenauslieferungsSearchParams,
      kollegenauslieferungSearchData: this.getKollegenauslieferungSearchData,
    };
    await this.actiongetKollegenauslieferungData(payload);
  }

  get items() {
    return this.getKollegenauslieferung.items;
  }

  async mounted() {}

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      align?: string;
      class?: string | string[];
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('zrNummer'), value: 'zr_number', width: '65px', align: 'center' },
      { text: this.$t('name'), value: 'gs_name', width: '100px' },
      { text: this.$t('zip_circle'), value: 'zip_circle', width: '155px' },
      { text: this.$t('deliver_abroad'), value: 'deliver_abroad', align: 'center' },
      { text: this.$t('deliver_abroad_country'), value: 'deliver_abroad_country' },
      { text: this.$t('deliver_island'), value: 'deliver_island', align: 'center' },
      { text: this.$t('deliver_island_name'), value: 'deliver_island_name' },
      { text: this.$t('delivery_colleague'), value: 'delivery_colleague', align: 'center' },
      { text: this.$t('delivery_colleague_contact'), value: 'delivery_colleague_contact' },
      { text: this.$t('delivery_colleague_email'), value: 'delivery_colleague_email' },
      { text: this.$t('delivery_colleague_phone'), value: 'delivery_colleague_phone' },
      { text: this.$t('delivery_colleague_productgroup'), value: 'delivery_colleague_productgroup' },
      { text: this.$t('delivery_colleague_condition'), value: 'delivery_colleague_condition' },
    ];
    return headers;
  }

  get searchMenuObj() {
    return {
      facetSearch: this.facetSearch,
      menuDisabled: this.menuDisabled,
      menuItems: this.menuItems,
      hasResults: this.hasResults,
      chipData: this.chipData,
    };
  }

  get hasResults() {
    return this.menuItems.length > 0 && !!this.menuItems[0]?.field;
  }

  private async selectionUpdateOptions(newVal: any, action: any) {
    if (action == 'search') {
      this.searchFacetModeInKollegenauslieferung(newVal);
    } else if (action == 'reset') {
      this.resetAllFacetOptionsAndSearch();
      this.getKollegenauslieferungsData();
    } else {
      if (this.kollegenauslieferungsSearchParams !== undefined) {
        this.kollegenauslieferungsSearchParams.dataOption = newVal;
        this.getKollegenauslieferungsData();
      }
    }
  }

  private searchFacetModeInKollegenauslieferung(e: any): void {
    const searchWord: string = e.filter;
    let selectedListItemId = document.querySelector('.v-list-item--highlighted .v-list-item__title')?.id;
    if (selectedListItemId != undefined) {
      let menuItem = this.menuItems.find((x: any) => x.field == selectedListItemId);
      this.facetSearch = false;
      this.searchInRealObjects(menuItem);
      return;
    }
    this.searchWord = searchWord;
    // if user press "enter" key again then make search in all fields
    if (this.facetSearch && this.menuItems.length != 0) {
      this.facetSearch = false;
      // return;
    }
    // handle facet search for words more than 2 letters
    if (searchWord?.length >= 2) {
      // const { start, end } = this.getStartEndPeriodForSearch();
      this.loadingFacet = true;
      this.makeFacetSearch(searchWord)
        .then((result: any) => {
          this.formatResultFromSearch(result);
          this.getKollegenauslieferungSearchData.zipCircleShort = result.zipCircleShort;
          this.getKollegenauslieferungSearchData.zipCircleShortExist = result.zipCircleShortExist;
          this.facetSearch = true;
          this.menuDisabled = false;
        })
        .finally(() => {
          this.loadingFacet = false;
        });
    } else if (searchWord?.trim().length === 0) {
      // load all invoices if press `Enter` with clear input
      this.getKollegenauslieferungsData();
    }
  }

  searchInRealObjects(item: any) {
    this.searchInRealKollegenauslieferung(item);
  }

  searchInRealKollegenauslieferung(item: any) {
    const searchParams = this.kollegenauslieferungsSearchParams; //this.searchParams;
    let valueSearchWord: string = this.searchWord;
    if (item.field == 'ZipCircle' && this.getKollegenauslieferungSearchData.zipCircleShortExist) {
      valueSearchWord = this.getKollegenauslieferungSearchData.zipCircleShort;
    }

    searchParams.andClauseFieldsIds.push({
      chipField: item.field,
      searchWord: valueSearchWord,
    });

    if (this.searchWord?.length >= 2 && item.title.length > 0) {
      this.getKollegenauslieferungSearchData.searchFacetFields =
        this.kollegenauslieferungsSearchParams.andClauseFieldsIds;
      this.actiongetKollegenauslieferungData({
        searchParams,
        kollegenauslieferungSearchData: this.getKollegenauslieferungSearchData,
      })
        .then((result: any) => {
          this.menuDisabled = true;
          if (item.field != 'all') {
            let valueSearch: string = this.searchWord;
            if (item.field == 'ZipCircle' && this.getKollegenauslieferungSearchData.zipCircleShortExist) {
              valueSearch = this.getKollegenauslieferungSearchData.zipCircleShort + '...';
            }
            this.chipData.push({
              chipText: `${item.fieldTranslated}: ${valueSearch}`,
              chipField: item.field,
              searchWord: valueSearch,
            });
          }
        })
        .finally(() => {
          this.resetFacetMenu();
        });
    }
  }

  resetAllFacetOptionsAndSearch() {
    this.resetChips();
    this.resetMenuItems();
    this.resetFacetMenu();
    this.kollegenauslieferungsSearchParams.andClauseFieldsIds = [];
    this.kollegenauslieferungsSearchParams.filter = '';
  }

  private resetMenuItems() {
    this.menuItems = [];
  }
  private resetChips() {
    this.chipData = [];
  }

  resetFacetMenu() {
    this.menuDisabled = true;
    this.facetSearch = false;
    this.searchWord = '';
  }

  private async getKollegenauslieferungsData() {
    const payload = {
      searchParams: this.kollegenauslieferungsSearchParams,
      kollegenauslieferungSearchData: this.getKollegenauslieferungSearchData,
    }; // sent search request from localStorage on reload
    await this.actiongetKollegenauslieferungData(payload)
      .then((result: any) => {
        logger.log('result :>> ', result);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  async makeFacetSearch(searchWord: any) {
    let payload = {
      searchWord: searchWord,
      chipDataString: this.chipDataString,
    };

    payload = { ...this.getKollegenauslieferungSearchData, ...payload };
    return this.actionGetKollegenauslieferungFacetSearch(payload);
  }

  get chipDataString() {
    return this.chipData.map((el: any) => `${el.chipField}:${el.searchWord}`).join(',');
  }

  formatResultFromSearch(result: any) {
    const fieldsWithResult = Object.keys(result).filter((key) => result[key] > 0);
    this.resetMenuItems();
    fieldsWithResult.forEach((element: string) => {
      const fieldName = element.charAt(0).toUpperCase() + element.slice(1);
      if (fieldName != 'ZipCircleShort' && fieldName != 'ZipCircleShortExist') {
        let valueSearch: string = this.searchWord;
        if (fieldName == 'ZipCircle' && result['zipCircleShortExist']) {
          valueSearch = result['zipCircleShort'] + '...';
        }
        const fieldNameTranslated = this.$i18n.tc(`facet_search.${fieldName}`);
        this.menuItems.push({
          title: `${fieldName}: ${valueSearch} (${result[element]})`,
          titleTranslated: `${fieldNameTranslated}: ${valueSearch} (${result[element]})`,
          fieldTranslated: fieldNameTranslated,
          field: fieldName,
        });
      }
    });
    if (fieldsWithResult.length === 0) {
      this.menuItems.push({
        titleTranslated: this.$i18n.tc('facet_search.no_results'),
      });
      return;
    }
    this.menuItems.unshift({
      titleTranslated: this.$i18n.tc('facet_search.all'),
      field: 'all',
      title: 'All',
    });
  }

  removeChip(chipText: any) {
    const index = this.chipData.findIndex((x) => x.chipText === chipText);
    this.chipData.splice(index, 1);
    const indexOdata = this.kollegenauslieferungsSearchParams.andClauseFieldsIds.findIndex(
      (x: any) => x.chipText === chipText
    );
    this.kollegenauslieferungsSearchParams.andClauseFieldsIds.splice(index, 1);

    this.menuItems = [];
    this.menuDisabled = true;
    this.getKollegenauslieferungsData();
  }

  get tableText() {
    return `${this.getKollegenauslieferung.total} Artikel`;
  }

  clearAllFilters() {
    this.actionUpdateKollegenauslieferungSearchData(kollegenauslieferungSearchData.defaultData());
    this.resetAllFacetOptionsAndSearch();
  }

  get isAllFiltersEmpty() {
    return (
      this.isKollegenauslieferungSearchDataEmpty &&
      !this.kollegenauslieferungsSearchParams.filter &&
      !this.chipDataString
    );
  }

  get isHasSearchData() {
    return (
      this.getKollegenauslieferung.total === 0 || this.kollegenauslieferungsIsLoading || this.isSearchDataHasNoValues()
    );
  }

  isSearchDataHasNoValues() {
    let values = Object.values(this.getKollegenauslieferungSearchData);
    let result = values.some((x: any) => (x + '').length > 0);
    return !result;
  }

  getValueZipCircle(value: any) {
    if (value.length <= 23) {
      return value;
    } else {
      let index = value.indexOf(',');
      if (index != -1 && index <= 23) {
        let lastindex = value.lastIndexOf(',', 23);
        return value.substring(0, lastindex);
      } else {
        return value.substring(0, 20) + '...';
      }
    }
  }

  getValueDeliveryColleagueProductgroup(value: any) {
    if (value.length <= 60) {
      return value;
    } else {
      let index = value.indexOf(';');
      if (index != -1 && index <= 60) {
        let lastindex = value.lastIndexOf(';', 60);
        return value.substring(0, lastindex);
      } else {
        return value.substring(0, 55) + '...';
      }
    }
  }
}
