/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import gsp, {
  GspUserRoleDetail,
  GspUserRoleDetailInner,
  GspUserRoleDetailModuleRole,
} from '@/shared/model/gspUserRoleDetail';
import { RoleOperation } from '@/shared/utils/Constants';
import { GspUserRole } from '@/shared/model/gspUserRole';
import { deepClone } from '@/shared/utils/generalUtils';
import { OdataItems } from '@/shared/model/OdataItems';
import { GspRole } from '@/shared/model/gspRole';
import { VBtn } from 'vuetify/lib';
import EditMultipleModuleRolesDialog from './edit-multiple-module-roles/edit-multiple-module-roles.vue';
import { UserActionType } from '@/shared/model/userActionsUserRoleChanges';

const logger = new Logger('module-role-table');
const contactModule = namespace('contact');
const gspRoleModule = namespace('gspRole');
const gspUserRoleModule = namespace('gspUserRole');
const gspServiceModule = namespace('gspService');
const gspUserRoleDetailModule = namespace('gspUserRoleDetail');

@Component({
  components: { VBtn, EditMultipleModuleRolesDialog },
})
export default class ModuleRoleTable extends Vue {
  @gspServiceModule.Action('getGspServices')
  private actionGetGspServices!: any;
  @gspUserRoleModule.Action('addRoleFromBackend')
  private actionAddRoleFromBackend!: any;
  @gspUserRoleModule.Action('addRoleDetailFromBackend')
  private actionAddRoleDetailFromBackend!: any;
  @gspUserRoleDetailModule.Action('getGspUserRoleDetail')
  private actionGetGspUserRoleDetail!: any;
  @gspUserRoleDetailModule.Action('updateGspUserRoleDetail')
  private actionUpdateGspUserRoleDetail!: any;
  @gspUserRoleDetailModule.Action('addUserFromManageMaxService')
  private actionAddUserFromManageMaxService!: any;
  @gspUserRoleDetailModule.Action('deleteUserFromManageMaxService')
  private actionDeleteUserFromManageMaxService!: any;
  @gspUserRoleDetailModule.Action('updateGspUserRoleDetailMultipleRoles')
  private actionUpdateGspUserRoleDetailMultipleRoles!: any;

  @gspUserRoleDetailModule.Action('getWarengruppeForMax')
  private actionGetWarengruppeForMax!: any;

  @gspUserRoleModule.Action('updateGspUserRole')
  private actionUpdateGspUserRole!: any;
  @gspUserRoleModule.Getter('getGspUserRoles')
  private userRoles!: any;

  @contactModule.Action('getMasterZrGsp')
  private actionGetMasterZrGsp!: any;

  @gspRoleModule.Getter('getGspRoles')
  private gspRoles!: OdataItems<GspRole>;

  @contactModule.Getter('getCompanyZrs')
  private companyZrNums!: any;

  @Prop()
  private serviceModules!: any;
  @Prop()
  private zrNumberArr!: any;
  @Prop({ default: false })
  private noAccess!: boolean;

  @Prop()
  private userRole!: GspUserRole;
  @Prop()
  private service!: any;
  @Prop()
  private serviceModuleCommonText!: any;
  @Prop()
  private contactId!: any;
  @Prop()
  private contactData!: any;
  @Prop()
  private contactEmail!: any;
  @Prop()
  private isSingleRow!: boolean;

  moduleZrNumArr: GspUserRoleDetailInner[] = [];
  moduleZrNumArrBackup: GspUserRoleDetailInner[] = [];

  editMultipleModuleRolesDialog: {
    dialog: boolean;
    editedUserRoleDetails: GspUserRoleDetailInner[];
  } = {
    dialog: false,
    editedUserRoleDetails: [],
  };

  // private async getGspServices() {
  //   await this.actionGetGspServices()
  //     .then((result: any) => {
  //       this.servicesArr = result.value;
  //     })
  //     .catch((err: any) => {
  //       logger.error(err);
  //     });
  // }
  servicesArr: any = [];
  // modulesArr: any = [];
  // attributesArr: any = [];

  get isHaveCommonModules() {
    return this.serviceModules?.filter((x: any) => x.isCommon).length > 1;
  }

  get roleServices() {
    return this.roles?.map((x: any) => x.id);
    // return this.userRole?.gspUserRoleDetails.map((x:any) => x.serviceId);
  }

  get isGspService() {
    // return this.serviceId == 5; // !! (AD-103) bad approach since `serviceId` position can change (encounter in Prod) and Service can be easily renamed, so for now just use `GetMasterZrGsp` logic for Services where multiple ZrNummer used (only GSP for now (on 2024-06))
    // (AD-103) decided to use separate backend field in GspService table
    return this.service.is_call_zr_master;
  }

  get isMaxService() {
    return this.service.name?.toLowerCase().includes('managemax');
  }
  get noMaxAccessWithoutWaren() {
    return this.isMaxService && this.warmax.length == 0;
  }

  get zrNumberArrWithMaster() {
    if (this.isGspService) {
      let res = [...new Set(this.zrNumberArr.concat(this.zrMasterGsp))];
      return res;
    }
    return this.zrNumberArr;
  }

  currentRoleDetail?: GspUserRoleDetail = gsp.parse({});
  currentRoleDetailInit?: GspUserRoleDetail = gsp.parse({}); // to compare initial and changed role to log added/deleted role in database

  async created() {
    var promiseAll = [this.getMasterZrForGsp()];
    if (this.isMaxService) {
      promiseAll.push(this.getWarengruppeForMax());
    }
    await Promise.all(promiseAll);
  }



  zrMasterGsp: any = [];
  private dataLoaded = false;
  initComponent() {
    this.logicFromMounted();
    this.dataLoaded = true;
  }


  //#region (AD-197 Warengrupp for Managemax logic)
  private warmax:any = [];
  private warmaxPrevious:any = [];
  private maxWarengruppe:any = [];
  private warenBackendAction = '';
  private warenDiff = '';
  async getWarengruppeForMax() {
    await this.actionGetWarengruppeForMax(this.contactEmail).then((result:any) => {
      console.log('result :>> ', result);
      this.maxWarengruppe = result.war ? JSON.parse(result.war) : [];//.filter((x: any)=> !x.ParentId)
      if (result.user && result.user.length > 4) { // empty array has length 2
        console.log('objeresult.userct :>> ', result.user);
        let user = JSON.parse(result.user);
        console.log('user :>> ', user);
        if (user[0]?.Warengruppen) {
          this.warmax = user[0]?.Warengruppen;
          this.warmaxPrevious = [...this.warmax];
        }
      }
      // .sort((a: any, b:any) => a.Id > b.Id ? 1 : -1);
      console.log('this.maxWarengruppe :>> ', this.maxWarengruppe);
    }).catch((err:any) => {
      logger.error(err);
    });
  }

  get isManageMaxHasRole() {
    return this.isMaxService && !!this.currentRoleDetail && (this.currentRoleDetail?.roleDetails[0] as any)?.moduleRoles[0]?.roles?.length > 0;
  }

  getWarenGruppeAction(){
    let currentWaren =this.warmax;
    let previousWaren =this.warmaxPrevious;

    let action = ''; // 5 4
    let diffResult = currentWaren.length - previousWaren.length;
    let warenDifference = [];
    console.log('currentWaren.length :>> ', currentWaren.length);
    console.log('previousWaren.length :>> ', previousWaren.length);
    console.log('diffResult :>> ', diffResult);

    if (diffResult == 1) {
      action = 'AddWarengruppe';
      warenDifference = currentWaren.filter((x: any) => !previousWaren.includes(x));
    } else  if (diffResult == -1) {
      action = 'DeleteWarengruppe';
      warenDifference = previousWaren.filter((x: any) => !currentWaren.includes(x));
    } else  if (diffResult > 1 || currentWaren.length == this.maxWarengruppe.length) {
      action = 'AddAllWarengruppe';
    } else  if (diffResult < -1 || previousWaren.length == 0) {
      action = 'DeleteAllWarengruppe';
    } else if (diffResult == 0) {
      // (AD-197) on creation roleDetail
      action = 'AddWarengruppe';
      warenDifference = currentWaren;
    }

    console.log(' action :>> ',  action);
    console.log('warenDifference :>> ', warenDifference);
    if (warenDifference.length > 0) {
      let resx = this.getWarrenNameIdPair(warenDifference[0]);
      console.log('waraa :>> ', resx);
    }

    this.warenDiff = this.getWarrenNameIdPair(warenDifference);
    console.log('this.warenDiff :>> ', this.warenDiff);
    this.warmaxPrevious = this.warmax;
    this.warenBackendAction = action;
    return action;
  }

  getMaxDeleteWarenAction() {
    let currentWaren =this.warmax;
    let previousWaren =this.warmaxPrevious;
    let action = 'DeleteWarengruppe';

    if (previousWaren.length > 1 || currentWaren.length == 0 ) {
      action = 'DeleteAllWarengruppe';
    }
    this.warenBackendAction = action;
    this.warenDiff = this.getWarrenNameIdPair([]);

    return action;
  }

  changeWarMax(v1: any) {
    console.log('v1 :>> ', v1);
    console.log(' this.currentRoleDetailInit :>> ',  this.currentRoleDetail);

    // let isMaxHasRole = !!this.currentRoleDetail && (this.currentRoleDetail?.roleDetails as GspUserRoleDetailInner[])?.some(x => x.moduleRoles && x.moduleRoles?.length > 0 && x.moduleRoles[0].roles && x.moduleRoles[0].roles?.length > 0);
    // let isMaxHasRole = !!this.currentRoleDetail && (this.currentRoleDetail?.roleDetails[0] as any)?.moduleRoles[0]?.roles?.length > 0;
    console.log('isMaxHasRole :>> ', this.isManageMaxHasRole);
    // let warenWithNames = this.warMaxWithName();

    console.log('this.warmax :>> ', this.warmax);
    console.log('this.warmaxPrevious :>> ', this.warmaxPrevious);
    this.getWarenGruppeAction();

    if (this.isManageMaxHasRole) {
      const maxPayload = {
        contactId: this.contactId,
        contactEmail: this.contactEmail,
        firstName: this.contactData.firstName,
        lastName: this.contactData.lastName,
        UserGroupId: 1, // discussed with Chaslau (for now always 1)
        zrNummernCsv: this.zrNumberArr?.join(',') + '',
        azureUserId: this.contactData.contactAzureId,

        azureGroupId: this.service.entra_Group,

        gspUserRoleId: this.currentRoleDetail?.id,
        serviceId: this.service.id,
        warengruppen: this.warmax,
        // warengruppenWithName: warenWithNames,
        actionType: this.warenBackendAction,
        warenDiff: this.warenDiff
      }
      console.log('maxPayload :>> ', maxPayload);
      this.actionAddUserFromManageMaxService(maxPayload);
    }
    // (AD-197) remove `ManageMax` role if manually remove last Warengruppe
    if (v1.length == 0) {
      this.manuallyRemoveManageMaxRole();
    } else if(!this.isManageMaxHasRole) {
      // (AD-197) manually create role if Warengruppe was selected before role
      this.manuallyAddManageMaxRole();
    }
  }



  get selectAllWarengruppe() {
    return this.warmax.length === this.maxWarengruppe.length;
  }

  get selectSomeWarengruppe() {
    return this.warmax.length > 0 && !this.selectAllWarengruppe;
  }

  get iconForAllWarengruppe() {
    if (this.selectAllWarengruppe) return 'mdi-close-box';
    if (this.selectSomeWarengruppe) return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  manuallyAddManageMaxRole() {
    let item = (this.currentRoleDetail?.roleDetails[0] as any)?.moduleRoles[0];
    console.log('this.userRole :>> ', this.userRole);
    console.log('this.currentRoleDetail :>> ', this.currentRoleDetail);
    console.log('this.currentRoleDetailInit :>> ', this.currentRoleDetailInit);
    console.log('item :>> ', item);
    if (!item) {
      let item2 = this.moduleZrNumArr.find((x:any) => x.moduleRoles.some((m:any) => m.name.toLowerCase().includes('managemax')))?.moduleRoles;
      let item3 = item2?.find((m:any) => m.name.toLowerCase().includes('managemax'));

      if (item3) {
        item = item3;
      }
    }

    let roles = [this.roleServices[0]];
    console.log('roles :>> ', roles);
    item.roles = roles;
    if (roles.length > 0 && this.roleServices[0]) {
     this.changeModuleRole(roles,item, undefined);
    }

  }
  manuallyRemoveManageMaxRole() {
    let item = (this.currentRoleDetail?.roleDetails[0] as any)?.moduleRoles[0];
    item.roles = []; // delete roles for ManageMax if remove all `Warengruppe`
    this.changeModuleRole([],item, undefined);
  }

  toggleAllWarenGruppe() {
    this.$nextTick(() => {
      if (this.selectAllWarengruppe) {
        this.warmax = [];
        this.getWarenGruppeAction();
        this.manuallyRemoveManageMaxRole();
      }
      else {
        this.warmax = this.maxWarengruppe.map((x: any) => x.Id);
        // this.reportParams.employeeId = this.employees
        //   .map((x: any) => x.id)
        //   .slice();
        this.changeWarMax(this.warmax);
      }
    });
  }

  getWarrenNameIdPair(array: Array<any>) {
    let result = '';
    if (array.length > 0) {
      const id = array[0];
      let warrenRecord = this.maxWarengruppe.find((x:any)=> x.Id == id);
      console.log('getWarrenNameIdPairById warrenRecord:>> ', warrenRecord);
      result = `${warrenRecord.Name};;;${warrenRecord.Id}`
    } else {
      let number = this.warmax.length == this.maxWarengruppe.length || this.warmax.length == 0 ? this.maxWarengruppe.length : this.warmax.length;

      result = `Alle ${number}`;
    }

    return result;
  }

  warMaxWithName() {
    console.log(' this.maxWarengruppe :>> ',  this.maxWarengruppe);
    console.log('this.warmax :>> ', this.warmax);


    let filter22 = this.maxWarengruppe.filter((x:any)=> this.warmax.includes(x.Id));
    console.log('filter22 :>> ', filter22);
    let filter = this.maxWarengruppe.filter((x:any)=> this.warmax.includes(x.Id)).map((x:any) =>
    ({id: x.Id,name: x.Name})
    );
    console.log(' war warMaxWithName filter :>> ', filter);
    return filter;
  }
  //#endregion

  async getMasterZrForGsp() {
    if (!this.isGspService) {
      this.initComponent();
      return;
    }
    // console.log(this.contact.companyZrNumber)
    let zrArray = this.zrNumberArr;
    // zrArray = zrArray.map(x=> x.trim());
    await this.actionGetMasterZrGsp(zrArray)
      .then((result: any) => {
        console.log('actionGetMasterZrGsp result :>> ', result.result);
        let resArr = result.result;
        //let difference = arr1.filter(x => !arr2.includes(x));
        let diff = resArr.filter((x: any) => !zrArray.includes(x));
        console.log('diff :>> ', diff);
        this.zrMasterGsp = diff;
      })
      .catch((err: any) => {
        logger.error(err);
      })
      .finally(() => this.initComponent());
  }

  @Watch('userRole')
  onUserRoleUpdated() {
    logger.debug('----userRoleUpdated----');
    this.getMasterZrForGsp();
  }

  async mounted() {
    // var promiseAll = [this.getGspServices()];
    // await Promise.all(promiseAll);
    // // const result = this.userRoles.items.find((role:any) => role.contactEmail == this.contact.email);
    // // console.log('mounted userRole result :>> ', result);
    // // console.log('mounted  contact:>> ', this.contact.email);
    // if (this.userRole?.zrNumRolesData) {
    //   this.userRoleData = JSON.parse(this.userRole?.zrNumRolesData);
    //   this.userRoleData.forEach((element: any, index) => {
    //     if (element?.zrNummer) {
    //       Vue.set(this.boxes, index, true);
    //       Vue.set(this.roleBoxes, index, element.roles);
    //     }
    //   });
    //   this.userRolesPayload = this.userRoleData;
    // }
  }

  private commonModules: any = {}; // (AD-194) for Add/Delete user from AzureGroup based on `entra_Group` for common Modules
  logicFromMounted() {
    this.currentRoleDetail = this.userRole?.gspUserRoleDetails.find((x: any) => x.serviceId === this.service.id);
      this.currentRoleDetailInit = this.currentRoleDetail != undefined ? deepClone(this.currentRoleDetail) : undefined;

    // this.serviceModules.forEach((el:any, index: number) => {
    //   this.moduleZrNumArr.push({'zrNummer': this.zrNumberArr[index],'module': el})
    // })
    console.log('this.userRole :>> ', this.userRole);
    // console.log('this.serviceModules OA:>> ', Object.assign({}, this.serviceModules));
    let modules = this.serviceModules;
    // console.log('this.serviceModules common init :>> ', Object.assign({},this.serviceModules));
    // console.log('this.serviceModules :>> ', this.serviceModules);

    if (this.isHaveCommonModules) {
      // if (this.serviceModules.length > 1 && this.serviceModules[0].name.includes(this.serviceModules[1].name)) {
      //   console.log('already all common modules concatenated :>> ');
      //   // (AD-95) fix bug that `common` long modules string is duplicated for each new Contact (without created role)
      //   modules = [this.serviceModules[0]];
      //   console.log('modules ex :>> ', modules);
      // } else {
      const result = this.serviceModules.reduce((acc: any, cur: any) => {
        // console.log('acc :>> ', Object.assign({},acc));
        //  console.log('cur :>> ', Object.assign({},cur));
        let doesExist = acc.find((a: any) => a.isCommon);
        // console.log('object :>> ', object);
        // console.log('doesExist :>> ', Object.assign({},doesExist));

        if (doesExist && cur.isCommon) {
          if (!doesExist.name.includes(cur.name)) {
            doesExist.name = `${doesExist.name},${cur.name}`;
            doesExist.isCommon = true;
          }

          if (!!cur.entra_Group && (!doesExist.entra_Group || !doesExist.entra_Group.includes(cur.entra_Group))) {
            doesExist.entra_Group = `${doesExist.entra_Group},${cur.entra_Group}`;
            doesExist.isCommon = true;
          }
        } else {
          acc.push(cur);
        }
        return acc;
      }, []);
      modules = result;
      this.commonModules = result;
      // }
    }

    const roleDetails =
      this.currentRoleDetail && this.currentRoleDetail.roleDetails && this.currentRoleDetail.roleDetails.length > 0;

    this.moduleZrNumArr = [];
    // console.log('before    this.moduleZrNumArr:>> ',   this.moduleZrNumArr );

    if (this.userRole?.id && this.userRole.gspUserRoleDetails.length > 0 && roleDetails) {
      //console.log('this.currentRoleDetail!.roleDetails :>> ', this.currentRoleDetail!.roleDetails);
      // !! TODO(AD-89) decide how to read existing data if Modules `IsCommon` checkbox was changed
      this.moduleZrNumArr = this.currentRoleDetail!.roleDetails as GspUserRoleDetailInner[];
    // console.log('parse this.currentRoleDetail!.roleDetails:>> ', JSON.stringify(this.currentRoleDetail!.roleDetails));
    // console.log('parse :>> ', JSON.stringify(this.moduleZrNumArr));
    // console.log('after    this.moduleZrNumArr:>> ',   this.moduleZrNumArr );


      //console.log('this.moduleZrNumArr LEN :>> ', this.moduleZrNumArr.length);
      // console.log('this.this.zrNumberArrWithMaster.length :>> ', this.zrNumberArrWithMaster.length);
      if (this.zrNumberArrWithMaster.length > this.moduleZrNumArr.length && this.isGspService) {
        let currentZrs = this.moduleZrNumArr.map((x: any) => x.zrNummer + ''); // (AD-0) convert `moduleZrNumArr` to string (string returned from `actionGetMasterZrGsp` action ) to avoid duplicates with `ZrNummer` on frontend
        // console.log('currentZrs :>> ', currentZrs);
        let diff = this.zrNumberArrWithMaster.filter((x: any) => !currentZrs.includes(x));
        // console.log('diff :>> ', diff);
        
        this.addRolesToExisting(diff);
      }
      if (this.moduleZrNumArr.length) {
        // TODO: specify from Chaslau what to do if changes in Module isCommon status
      }
    } else {
      console.log('init :>> ');

      this.initNewData(modules);
    }
  }

  addRolesToExisting(zrs: any) {
    zrs.forEach((el: any, index: number) => {
      const moduleRoles = this.serviceModules.map((x: any) => {
        return { id: x.id, name: x.name, roles: [] };
      });

      this.moduleZrNumArr.push({ zrNummer: el.toString().trim(), moduleRoles: moduleRoles }); // was zrNummerAll which is wrong for multiple zrNummer
    });
  }

  initNewData(modules: any) {
    console.log('modules :>> ', modules);
    console.log('this.isSingleRow :>> ', this.isSingleRow);
    if (this.isSingleRow) {
      const moduleRoles = modules.map((x: any) => {
        return { id: x.id, name: x.name, isCommon: x.isCommon, roles: x.roles ?? [] };
      });
      const zrNummerAll = this.zrNumberArr.map((x: any) => x.trim()).join(',');

      this.moduleZrNumArr.push({ zrNummerAll: zrNummerAll, moduleRoles: moduleRoles });
    } else {
      this.zrNumberArrWithMaster.forEach((el: any, index: number) => {
        const moduleRoles = modules.map((x: any) => {
          return { id: x.id, name: x.name, roles: x.roles ?? [] };
        });

        this.moduleZrNumArr.push({ zrNummer: el.toString().trim(), moduleRoles: moduleRoles }); // was zrNummerAll which is wrong for multiple zrNummer
      });
    }
  }

  get gspUserRoleId() {
    return (this.userRole?.id || this.currentRoleDetail?.gspUserRoleId)!;
  }

  async changeModuleRole(newRoles: number[], item: GspUserRoleDetailModuleRole, /*modPosIndex: number,*/ zr: string | undefined) {
    // console.log('newRoles :>> ', newRoles);
    console.log('newRolesitem :>> ', item);
     console.log('zr :>> ', zr);
    const previousItemState = this.currentRoleDetailInit?.roleDetails as GspUserRoleDetailInner[];
    let prev: GspUserRoleDetailModuleRole | null | undefined = null;

    if (previousItemState) {
      for (let roleDetail of previousItemState) {
        if (roleDetail.zrNummer != zr) continue;

        let found = roleDetail.moduleRoles?.find((x) => x.id == item.id);
        if (found != undefined) {
          prev = found;
          break;
        }
      }
    }

    let operation = '';
    let difference: number[] = [];
    if (!prev?.roles || newRoles.length > prev.roles?.length) {
      operation = RoleOperation.add;
      difference = newRoles.filter((x: any) => !prev?.roles?.includes(x));
    } else if (!!prev?.roles) {
      // console.log('prev.roles?.lengths - ev.length > 1 :>> ', prev.roles?.length - ev.length);
      operation = prev.roles?.length - newRoles.length > 1 ? RoleOperation.deleteAll : RoleOperation.delete;
      difference = prev.roles.filter((x) => !newRoles.includes(x));
    }

    for (let roleId of difference) {
      let roleDetailsModel = gsp.parse({});

      if (this.currentRoleDetail?.id) {
        console.log('this.currentRoleDetail?.id :>> ', this.currentRoleDetail?.id);
        roleDetailsModel = this.currentRoleDetail;
      }
      // if (!this.userRole?.id) {
      roleDetailsModel.contactId = this.contactId;
      roleDetailsModel.contactEmail = this.contactEmail;
      // }
      roleDetailsModel.actionType = operation;
      roleDetailsModel.roleId = roleId;

      if (operation == RoleOperation.deleteAll) {
        let roleNames = this.roles.filter((x: any) => prev?.roles?.includes(x.id)).map((x: any) => x.name);
        roleDetailsModel.roleName = `(${roleNames.length} roles) ${roleNames.join(',')}`;
      } else {
        // The typescript compiler seems to be buggy here, so I needed to mark these variables as any.
        let role = this.roles.find((x: any) => x.id == roleId);
        roleDetailsModel.roleName = (role as any).name ?? '';
      }

      roleDetailsModel.moduleId = item.id!;
      roleDetailsModel.isCommonModule = item.isCommon!;

      // console.log('aaaa object this.moduleZrNumArr :>> ', Object.assign({},this.moduleZrNumArr));

      // console.log('rolesCount :>> ', rolesCount);
      roleDetailsModel.roleDetails = this.moduleZrNumArr;
      roleDetailsModel.gspUserRoleId = this.gspUserRoleId;
      roleDetailsModel.serviceId = this.service.id;
      roleDetailsModel.zrNummer = zr;

      roleDetailsModel.contactAzureId = this.contactData.contactAzureId;
      roleDetailsModel.prevRoles =prev?.roles;
      roleDetailsModel.roleDiff = difference;
      console.log('difference :>> ', difference);
      // roleDetailsModel.modulesEntraGroups = item.isCommon ? this.commonModules.find((x:any)=> x.id == item.id)?.entra_Group : this.serviceModules.find((x:any)=> x.id == item.id)?.entra_Group;


      console.log('roleDetailsModel :>> ', roleDetailsModel);
      console.log('roleDetailsModel  this.service:>> ', this.service);
      await this.actionUpdateGspUserRoleDetail(roleDetailsModel)
        .then((result: any) => {
          console.log('change ModuleRole actionUpdateGspUserRoleDetail result :>> ', result);
          this.currentRoleDetail = gsp.parse(result.result);
          this.currentRoleDetail.gspUserRole = null; // avoid  error when select multiple Roles for nonexisting `UserRole` (or need `parse` `UserRole` model)

          this.currentRoleDetailInit = Object.assign({}, gsp.parse(result.result));
          if (this.isMaxService) {
            this.getWarenGruppeAction();
            console.log('roleDetailsModel managemax :>> ');
            if (roleDetailsModel.actionType==RoleOperation.add) {
              const maxPayload = {
                contactId: this.contactId,
                contactEmail: this.contactEmail,
                firstName: this.contactData.firstName,
                lastName: this.contactData.lastName,
                UserGroupId: 1, // discussed with Chaslau (for now always 1)
                zrNummernCsv: this.zrNumberArr?.join(',') + '',
                azureUserId: this.contactData.contactAzureId,

                azureGroupId: this.service.entra_Group,

                serviceId: this.service.id,
                gspUserRoleId: this.currentRoleDetail?.id,
                warengruppen: this.warmax,
                // warengruppenWithName: warenWithNames,
                actionType: this.warenBackendAction,
                warenDiff: this.warenDiff
              }
              this.actionAddUserFromManageMaxService(maxPayload);
            } else {
              this.actionDeleteUserFromManageMaxService(
                { contactId: this.contactId,contactEmail: this.contactEmail,
                  azureUserId: this.contactData.contactAzureId,
                  azureGroupId: this.service.entra_Group,
                  gspUserRoleId: this.currentRoleDetail?.id,
                  serviceId: this.service.id,
                  actionType: this.getMaxDeleteWarenAction(),
                  warenDiff: this.warenDiff});
              if(!this.isManageMaxHasRole) this.warmax = []; // (AD-197 clear managemax roles)
            }

          }
          if (!this.userRole?.id) {
            let payload = result.result.gspUserRole;
            payload.gspUserRoleDetails = [this.currentRoleDetail];
            this.actionAddRoleFromBackend(payload);
          } else {
            this.actionAddRoleDetailFromBackend(result.result);
          }
        })
        .catch((err: any) => {
          logger.error(err);
        });

        this.$emit('click:saveChangeModuleRole', this.contactId);
    }

  }

  changeRoleValue(){
    //this.$emit('click:saveChangeModuleRole', this.contactId);
  }

  //#region
  isSaving = false;
  userRolesPayload: any = [];

  boxes: Array<boolean> = [false];
  roleBoxes: Array<any> = [];
  userRoleData = [];

  // get userRole() {
  //   // const result = this.userRoles.items.find((role: any) => role.contactEmail == this.contact.email);
  //   const result: any = '';
  //   return result;
  // }

  get roles(): GspRole[] {
    var serviceRoles = this.gspRoles.items.filter((r: any) => r.rolesDataServiceIds.includes(this.service.id));
    return serviceRoles;
    return this.gspRoles.items;
  }

  not(e: any) {
    e.preventDefault();
    e.stopPropagation();

    console.log('e :>> ', e);
  }

  changeZrNumbBox(ev: any, item: any, i: any) {
    console.log('ev :>> ', ev);
    console.log('item :>> ', item);
    console.log('i :>> ', i);
    console.log('this.gspRoles :>> ', this.gspRoles);
    if (!ev) {
      this.removeZrNumRole('zrNum', i);
    }
  }

  removeZrNumRole(source: any, index: number) {
    this.userRolesPayload[index] = null;
    if (source == 'zrNum') {
      Vue.set(this.roleBoxes, index, null);
    }
  }

  changeRole(ev: any, item: any, i: any) {
    if (ev.length == 0) {
      this.removeZrNumRole('role', i);
    }
    console.log('ev :>> ', ev);
    console.log('item :>> ', item);
    console.log('i zr :>> ', i);

    let obj = { zrNummer: item.trim(), roles: ev };
    this.userRolesPayload[i] = obj;
    // this.boxes[i] = ev.length>0 ;
    Vue.set(this.boxes, i, ev.length > 0);

    // Array.prototype.splice
    // vm.items.splice(indexOfItem, 1, newValue)
  }

  save() {
    console.log('save roles:>> ');

    // const payload = {
    //   id: this.userRole?.id || undefined,
    //   contactId: this.contact.recordID,
    //   contactEmail: this.contact.email,
    //   zrNumRolesData: JSON.stringify(this.userRolesPayload),
    // };
    // console.log('payload :>> ', payload);

    // this.actionUpdateGspUserRole(payload);
  }
  //#endregion

  private onEditMultipleModuleRoles() {
    //const oldRoleDetails = this.currentRoleDetailInit!.roleDetails as GspUserRoleDetailInner[];
    //console.log('##############################################-------------------on on EditMultipleModuleRoles-----------------------------------------  ===>>> oldRoleDetails  ', oldRoleDetails);
    this.editMultipleModuleRolesDialog.editedUserRoleDetails = deepClone(this.moduleZrNumArr);
    this.editMultipleModuleRolesDialog.dialog = true;
  }

  private onEditMultipleModuleRolesDialogClose() {
    this.editMultipleModuleRolesDialog.dialog = false;
  }

  private async onEditMultipleModuleRolesDialogSave(editedInnerUserRoleDetails: GspUserRoleDetailInner[]) {
    this.editMultipleModuleRolesDialog.dialog = false;
    const newRoleDetails = this.moduleZrNumArr;
    for (let innerUserRoleDetail of editedInnerUserRoleDetails) {
      if (innerUserRoleDetail.moduleRoles == undefined) continue;

      for (let moduleRole of innerUserRoleDetail.moduleRoles) {
        const moduleRoleInNewRoleDetails = newRoleDetails
          .find((x) => x.zrNummer == innerUserRoleDetail.zrNummer)
          ?.moduleRoles?.find((x) => x.name == moduleRole.name);
        if (moduleRoleInNewRoleDetails != undefined) moduleRoleInNewRoleDetails.roles = moduleRole.roles;
      }
    }

    const userRoleDetailToUpdate = <GspUserRoleDetail>{ ...this.currentRoleDetail };
    userRoleDetailToUpdate.roleDetails = newRoleDetails;
    userRoleDetailToUpdate.contactId = this.contactId;
    userRoleDetailToUpdate.serviceId = this.service.id;

    // Structure needed to add a log entry for each changed module role.
    let moduleRoleChanges: {
      ContactId: number;
      ServiceId: number;
      ContactEmail: string;
      ActionType: UserActionType;
      RoleId?: number;
      ModuleId?: number;
      IsCommonModule?: boolean;
      RoleName: string;
      ZrNummer?: string;
    }[] = [];
    //this.currentRoleDetailInit = deepClone(userRoleDetailToUpdate);
    // Figure out what has changed and create respective log entries
    //let oldRoleDetails: GspUserRoleDetailInner[];

    if (this.currentRoleDetailInit != undefined)
    {
      const oldRoleDetails = this.currentRoleDetailInit!.roleDetails as GspUserRoleDetailInner[];
      for (let oldRoleDetail of oldRoleDetails) {
        let newRoleDetail = newRoleDetails.find((x) => x.zrNummer == oldRoleDetail.zrNummer);
        for (let oldModuleRole of oldRoleDetail.moduleRoles ?? []) {
          let newModuleRole = newRoleDetail?.moduleRoles?.find((x) => x.id == oldModuleRole.id);
          let addedModuleRoles = newModuleRole?.roles?.filter((x) => !oldModuleRole.roles?.includes(x));
          let deletedModuleRoles = oldModuleRole.roles?.filter((x) => !newModuleRole?.roles?.includes(x));
          for (let addedModuleRole of addedModuleRoles ?? []) {
            moduleRoleChanges.push({
              ContactId: this.contactId,
              ServiceId: userRoleDetailToUpdate.serviceId,
              ContactEmail: this.contactEmail,
              ActionType: 'Add',
              RoleId: addedModuleRole,
              ModuleId: newModuleRole?.id,
              IsCommonModule: newModuleRole?.isCommon,
              RoleName: this.roles.find((x: any) => x.id == addedModuleRole)?.name ?? '-',
              ZrNummer:newRoleDetail?.zrNummer,
            });
          }
          for (let deletedModuleRole of deletedModuleRoles ?? []) {
            moduleRoleChanges.push({
              ContactId: this.contactId,
              ServiceId: userRoleDetailToUpdate.serviceId,
              ContactEmail: this.contactEmail,
              ActionType: 'Delete',
              RoleId: deletedModuleRole,
              ModuleId: newModuleRole?.id,
              IsCommonModule: newModuleRole?.isCommon,
              RoleName: this.roles.find((x: any) => x.id == deletedModuleRole)?.name ?? '-',
              ZrNummer:newRoleDetail?.zrNummer,
            });
          }
        }
      }
    }
    else
    {

      for (let newRoleDetail of editedInnerUserRoleDetails) {

        for (let newdModuleRole of newRoleDetail.moduleRoles ?? []) {
          let newModuleRole = newRoleDetail?.moduleRoles?.find((x) => x.id == newdModuleRole.id);
          let addedModuleRoles = newModuleRole?.roles;
          for (let addedModuleRole of addedModuleRoles ??[]) {
            moduleRoleChanges.push({
              ContactId: this.contactId,
              ServiceId: userRoleDetailToUpdate.serviceId,
              ContactEmail: this.contactEmail,
              ActionType: 'Add',
              RoleId: addedModuleRole,
              ModuleId: newModuleRole?.id,
              IsCommonModule: newModuleRole?.isCommon,
              RoleName: this.roles.find((x: any) => x.id == addedModuleRole)?.name ?? '-',
              ZrNummer:newRoleDetail?.zrNummer,
            });
          }
        }

      }
    }



    this.currentRoleDetailInit = deepClone(userRoleDetailToUpdate);

    const payload = {
      userRoleDetail: userRoleDetailToUpdate,
      moduleRoleChanges: moduleRoleChanges,
    };
    await this.actionUpdateGspUserRoleDetailMultipleRoles(payload)
    .then((result: any) => {
      if (!this.userRole?.id) {
        console.log('result.result.gspUserRole :>> ', result.result.gspUserRole);
        let payload = result.result.gspUserRole;
        payload.gspUserRoleDetails = [this.currentRoleDetail];
        this.actionAddRoleFromBackend(payload);
      } else {
        this.actionAddRoleDetailFromBackend(result.result);
      }
    })
    .catch((err: any) => {
      logger.error(err);
    });;
  }

  getNameCompany(zrnumer: string, zrnumerAll: string) {
    let valueZRNummer: string = '';

    if (zrnumer != undefined && zrnumer.toString().length != 0) {
      valueZRNummer = zrnumer.toString().trim();
    } else if (zrnumerAll != undefined && zrnumerAll.toString().length != 0) {
      valueZRNummer = zrnumerAll.toString().trim();
    }
    let companyName = this.companyZrNums.items.find((x: any) => x.zrNummer == valueZRNummer)?.companyName;
    return companyName;
  }
}
